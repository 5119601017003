import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { News } from "../../../../classes/News";
import Slider from "react-slick";
import { Type } from "../../Typography";
import { colors } from "../../../../vars/palette";
import NewsSlide from "./NewsSlide";
import DipraButton from "../../DipraButton";
import { HyperLink } from "../../../../classes/HyperLink";

const Wrap = styled.div<{ isBreakpointSmall?: boolean; cardHeight?: number }>`
  margin: 1rem 0;
  .slick-track {
    display: ${(props) => (props.isBreakpointSmall ? "block" : "flex")};
  }
  .slick-list {
    overflow-x: hidden;
    overflow-y: visible;
    padding: 60px 50px !important;
  }
  .slick-slide {
    padding: 0 15px;
    outline: none !important;
  }

  .carousel-item {
    height: ${(props) =>
      props.cardHeight ? `${props.cardHeight}px` : "unset"};
  }

  .arrow-right {
    svg {
      bottom: 50%;
      color: ${colors.green.hex};
      position: absolute;
    }

    .ar-right {
      right: -29px;
    }

    .ar-left {
      left: -29px;
    }
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const NextArrow = (props: {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<SVGElement>;
}) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className ? className + " " : ""}arrow-right`}
      style={{ ...style }}>
      <FaAngleRight className="ar-right" onClick={onClick} size="40px" />
    </div>
  );
};

const PrevArrow = (props: {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<SVGElement>;
}) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className ? className + " " : ""} arrow-right`}
      style={{ ...style }}>
      <FaAngleLeft onClick={onClick} size="40px" className="ar-left" />
    </div>
  );
};

const DipraNewsSlider = ({
  title,
  showMoreButton,
  filter
}: {
  title: string;
  showMoreButton?: boolean;
  filter?: string;
}) => {
  const data = useStaticQuery(graphql`
    query {
      allNews: allContentfulNews(
        sort: { fields: date, order: DESC }
        filter: { date: { gt: "0" } }
        limit: 5
      ) {
        edges {
          node {
            ...NodeNewsFields
          }
        }
      }
        newsTag: allContentfulNews(
        sort: { fields: date, order: DESC }
        filter: {tags: {in: "News"}, date: { gt: "0" } }
        limit: 5
      ) {
        edges {
          node {
            ...NodeNewsFields
          }
        }
      }
        commentaryTag: allContentfulNews(
        sort: { fields: date, order: DESC }
        filter: {tags: {in: "Opinions & Commentary"}, date: { gt: "0" } }
        limit: 5
      ) {
        edges {
          node {
            ...NodeNewsFields
          }
        }
      }
    }
  `);

  let news
  if (filter && filter === "News") {
    news = new News(data.newsTag.edges)
  } else if ( filter && filter === "Commentary") {
    news = new News(data.commentaryTag.edges)
  } else {
    news = new News(data.allNews.edges);

  }


  const settings = {
    arrows: true,
    centerMode: true,
    className: "slider variable-width",
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 3,
    swipeToSlide: true,
    variableWidth: true,
    // vertical: true,
    verticalSwiping: true
  };
  return (
    <Container>
      <Wrap>
        <Type.H3
          style={{
            color: colors.blue.hex,
            fontSize: "2rem",
            textAlign: "center",
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "4rem",
            marginBottom: "-2rem"
          }}>
          {title}
        </Type.H3>
        <Slider {...settings}>
          {news &&
            news.list &&
            news.list.map((article, i) => {
              return <NewsSlide article={article} key={`sliderArticle${i}`} />;
            })}
        </Slider>
        {showMoreButton && (
          <ButtonWrap>
            <DipraButton
              link={
                new HyperLink({
                  to: "/news",
                  target: `_self`,
                  text: `More News`
                })
              }
              color={colors.green.hex}
              textColor={colors.white.hex}
            />
          </ButtonWrap>
        )}
      </Wrap>
    </Container>
  );
};

export default DipraNewsSlider;
